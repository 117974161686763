.home-selector-wrapper{
  padding: 8rem;
  display: flex;
  justify-content: center;
}

.home-submit-wrapper{
  display: flex;
  justify-content: center;
}

.landing-page-button {
  background-color: rgb(64,122,188)!important;
  color: white!important;
}

.row-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;
  width: 20rem;
}

.column {
  flex: 0 0 48%; /* Adjust if necessary */
  display: flex;
  flex-direction: column;
  text-align: center;
}