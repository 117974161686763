.years-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  position: relative;
  background-color: #ccc8c83d;
  height: 2rem; 
  margin-top: 1rem;
}

.year-line{
  font-size: small;
  position: absolute;
}

.year-label{
  font-size: small;
  position: relative;
  top: 2rem;
  right: 0.55rem;
}