.App {
  margin-inline: 10rem;
  margin-top: 2rem;
  margin-bottom: 1rem;
  font-family: 'Roboto', sans-serif;
}

/* Override for screens less than 1500px wide */
@media (max-width: 1500px) {
  .App {
    margin-inline: 5rem;
  }
}

.body {
  background-color: #ffffff59;
}


.form-control{ 
  text-align: center;
  font-size: 0.75rem;
  line-height: 0.75;
}

html {
  font-size: 70%;
}