.expired-message {
  background-color: #ffe0e0; 
  border: 1px solid #ff0000;
  padding: 10px;
  margin-top: 20px;
  border-radius: 4px;
  text-align: center;
  color: #ff0000;
  font-weight: bold;
}

.deprecated-message {
  background-color: #fcffe0;
  border: 1px solid #ffd200;
  padding: 10px;
  margin-top: 20px;
  border-radius: 4px;
  text-align: center;
  color: #ff9500;
  font-weight: bold;
}

.covered-message {
  background-color: #e0ffe0; 
  border: 1px solid #3c9b73;
  padding: 10px;
  margin-top: 20px;
  border-radius: 4px;
  text-align: center;
  color: #3c9b73;
  font-weight: bold;
}

.lts-message {
  background-color: #e0ffe0; 
  border: 1px solid #3c9b73;
  padding: 10px;
  margin-top: 20px;
  border-radius: 4px;
  text-align: center;
  color: #3c9b73;
  font-weight: bold;
}