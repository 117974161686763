.timeline-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  padding: 3rem 0;
  position: relative; /* This ensures that child elements with absolute positioning are contained within this element */
  overflow: hidden;
}
