.event-row {
  position: relative;
  width: 100%;
  height: 24px;
}

.event-line {
  position: absolute;
  top: 50%;
  width: 100%;
  height: 2px;
  background-color: #aaaaaa4f;
}

.event {
  position: absolute;
  height: 24px;
  border-radius: 4px;
  text-align: center;
  color: white;
  font-size: 10px;
  line-height: 16px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-weight: bold;
}

.event.red {
  background-color: #e35a6a;
}

.event.yellow {
  background-color: #fcd363;
}

.event.green {
  background-color: #3c9b73;
}

.simulated-support {
  padding: 5px 10px; 
  border-radius: 5px;
  background: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1)); 
}


.highlighted {
  border: 2.5px solid #ffc107;
  box-shadow: 0px 1px 10px 6px #fffaee;
}
