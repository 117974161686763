.foxsoft-logo{
  height: 4rem;
}

@media (prefers-reduced-motion: no-preference) {
  .foxsoft-logo {
    animation: spinOnce 0.5s linear;
  }
}

@keyframes spinOnce {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.home-button {
  display: flex;
  margin-inline: 10rem;
  width: -moz-fit-content;
  justify-content: space-between;
  margin-top: 3rem;
}

.date-picker-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.button-wrapper {
  /* padding: 66rem; */
}

.button-wrapper > button {
  background-color: rgb(64, 122, 188);
  color: white;
}

.button-wrapper > button:hover {
  background-color: rgb(64, 122, 188);
  color: white;
}

.button-wrapper > button:active {
  background-color: rgb(64, 122, 188)!important;
  color: white!important;
}



/* Override for screens less than 1500px wide */
@media (max-width: 1500px) {
  .home-button {
    margin-inline: 5rem;
  }
}

.foxsoft-logo svg {
  width: auto;
}