/* Override for screens less than 1500px wide */
@media (max-width: 1500px) {
  .flex-container {
      display: block!important;
  }
}

.flex-container {
  display: flex;
  flex-wrap: wrap; /* Allow items to wrap onto the next line */
  gap: 10px;
}

.flex-container > div {
  flex: 0 1 calc(50% - 10px); /* Do not grow, shrink as needed, starting width as 50% of parent minus gap */
}

/* This will target a div that is the only child of .flex-container */
.flex-container > div:only-child {
  flex: 1 0 100%; /* Allow it to grow, do not shrink, and set its base width to 100% */
}

.container-wrapper {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
}