.timeline-wrapper {
  box-shadow: -1px 1px 20px 4px #f3f2f2;
  padding: 2rem 4rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  width: 100%;
  margin-block: 15px;
}
