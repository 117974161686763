.floating-icon {
  position: fixed;
  right: 20px;
  bottom: 20px;
  width: 30px;
  height: 30px;
  z-index: 1000;
  transition: all 0.3s ease;
  display: flex;
  justify-content: center;
  align-items: center;
}

.floating-icon img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.floating-icon:hover {
  transform: scale(1.05);
}
